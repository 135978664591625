import { Link } from '@remix-run/react';
import GlitchEmoji from '~/components/shared/GlitchEmoji';
import { EDITOR_URL } from '~/utils/constants';

import './remix-card.css';

type RemixCardProps = {
  title: string;
  description: string;
  illustration: React.ReactNode;
  primaryColor?: string;
} & ({ remixUrl: string; url?: never } | { url: string; remixUrl?: never });

const RemixCard = ({
  title,
  description,
  illustration,
  primaryColor,
  remixUrl,
  url,
}: RemixCardProps) => {
  const linkTo = remixUrl ? `${EDITOR_URL}#!/${remixUrl}` : url!;

  return (
    <Link to={linkTo} className="remix-card">
      <div className="remix-card-content">
        <div className="remix-card-illustration">{illustration}</div>
        <div className="remix-card-details">
          <h4>{title}</h4>
          <p>{description}</p>
        </div>
      </div>
      <div className={`button full-width no-hover-effect ${primaryColor}`}>
        <GlitchEmoji />
        {remixUrl ? 'Remix' : "Let's go"}
      </div>
    </Link>
  );
};

export default RemixCard;

import { Link } from '@remix-run/react';
import { useDecision } from '@optimizely/react-sdk';
import { ClientOnly } from 'remix-utils/client-only';
import RemixCard from '~/components/shared/RemixCard';
import EleventyStarter from '~/assets/illustrations/11tyStarter.svg';
import GlitchInBioStarter from '~/assets/illustrations/glitchInBioStarter.svg';
import WebsiteStarter from '~/assets/illustrations/websiteStarter.svg';
import ReactStarter from '~/assets/illustrations/reactStarter.svg';
import NodeStarter from '~/assets/illustrations/nodeStarter.svg';
import SQLiteStarter from '~/assets/illustrations/sqliteStarter.svg';
import ExternalSite from '~/assets/illustrations/externalSite.svg';
import './remix-card-grid.css';

type RemixCardGridType = {
  displayAllProjects?: boolean;
};

const externalProject = {
  title: 'Add a site to Glitch',
  description: 'Share a project from another site',
  illustration: <img src={ExternalSite} alt="" />,
  url: '/external-project',
  primaryColor: 'primary-1',
};

const sampleProjects = [
  {
    title: 'Basic website',
    description: 'A simple website starter',
    illustration: <img src={WebsiteStarter} alt="" />,
    remixUrl: 'remix/glitch-hello-website',
    primaryColor: 'primary-2',
  },
  {
    title: 'Glitch in Bio',
    description: 'Your own free links page',
    illustration: <img src={GlitchInBioStarter} alt="" />,
    remixUrl: 'remix/glitch-in-bio',
    primaryColor: 'primary-3',
  },
  {
    title: 'Blog with Eleventy',
    description: 'As easy as blogging gets',
    illustration: <img src={EleventyStarter} alt="" />,
    remixUrl: 'remix/glitch-hello-eleventy',
  },
];

const allProjects = [
  ...sampleProjects,
  {
    title: 'Hello Node!',
    description: 'Full stack, ready to go',
    illustration: <img src={NodeStarter} alt="" />,
    remixUrl: 'remix/glitch-hello-node',
    primaryColor: 'primary-6',
  },
  {
    title: 'React',
    description: 'A static react builder',
    illustration: <img src={ReactStarter} alt="" />,
    remixUrl: 'remix/glitch-hello-react',
    primaryColor: 'primary-5',
  },
  {
    title: 'SQLite',
    description: 'A starter database',
    illustration: <img src={SQLiteStarter} alt="" />,
    remixUrl: 'remix/glitch-hello-sqlite',
    primaryColor: 'primary-4',
  },
];

const RemixCardGrid = ({ displayAllProjects }: RemixCardGridType) => {
  const [decision] = useDecision('share_external_sites');
  const shareExternalProject = decision.enabled;

  const projects = displayAllProjects ? allProjects : sampleProjects;
  return (
    <div className="remix-card-grid-container">
      <div className="remix-card-grid">
        {displayAllProjects && shareExternalProject && (
          <ClientOnly>{() => <RemixCard {...externalProject} />}</ClientOnly>
        )}
        {projects.map((project) => (
          <RemixCard key={project.title} {...project} />
        ))}
      </div>
      {!displayAllProjects && (
        <Link className="link remix-card-grid-link" to="/discover">
          Discover more starting points 👉🏾
        </Link>
      )}
    </div>
  );
};

export default RemixCardGrid;
